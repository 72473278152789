import React, { useRef } from "react";
import Styles from "./styles.module.scss";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

import emailjs from "@emailjs/browser";

const ContactUs = () => {
  const notify = () => toast("We got your response!");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_rbclu1w",
        "template_oqw5ful",
        form.current,
        "fFWRKPsvbjiwNP-eb"
      )
      .then(
        (result) => {
          console.log(result.text);
          notify()
          setTimeout(() => {
            window.location.href="/"
          }, 8000);
         
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
   
    <form ref={form} onSubmit={sendEmail}>
      {/* <label>Name</label>
      <input type="text" name="user_name" />
      <label>Email</label>
      <input type="email" name="user_email" />
      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" /> */}
<div>
      
        <ToastContainer />
      </div>
      <div className={Styles.contact_form}>
        <div className={Styles.form}>
          <div className={Styles.firstename}>
            <p>Job title</p>
            <input required
              name="job_title"
              className={Styles.input}
              placeholder="job_title"
            ></input>
          </div>
        </div>
      </div>
      <div className={Styles.contact_form}>
        <div className={Styles.form}>
          <div className={Styles.firstename}>
            <p>Firstname*</p>
            <input required
              name="user_firstName"
              className={Styles.input}
              placeholder="firstName"
            ></input>
          </div>
        </div>
        <div className={Styles.form}>
          <div className={Styles.lastname}>
            <p>Lastname*</p>
            <input required
              name="user_lastName"
              className={Styles.input}
              placeholder="Lastname"
            ></input>
          </div>
        </div>
      </div>

      <div className={Styles.contact_form}>
        <div className={Styles.form}>
          <div className={Styles.firstename}>
            <p>Your email*</p>
            <input required
              name="user_email"
              className={Styles.input}
              placeholder="Your email"
            ></input>
          </div>
        </div>
        <div className={Styles.form}>
          <div className={Styles.lastname} style={{width:"430px" }} >
            <p>Your Portfolio or LinkedIn*</p>
            <input required
              name="user_linkedin"
              className={Styles.input}
              placeholder="Your Portfolio or LinkedIn"
            ></input>
          </div>
        </div>
      </div>

      <div className={Styles.talk1}>
        <p>Tell us about you</p>
      </div>

      <p className={Styles.text}>
        We are a UI/UX design company that brings ideas to life through
        scalable,
      </p>
      <textarea required
        name="user_letter"
        className={Styles.textarea}
        placeholder="Cover Letter to hiring manager"
      ></textarea>
      
      {/* <div className={Styles.document1}>
        <label htmlFor="image">
          <p className="btn6 btn-primary1">Attach your CV</p>
        </label>

        <input id="image" type="file" hidden   name="user_image"></input>
        <p className={Styles.document1_p}>File size not more than 5 MB</p>
      </div> */}

      <p  className={Styles.document_text} >
        If you have a brief or document, share it with us here.
      </p>
   
      <p className={Styles.vacancy}>
        We will contact you if there is a vacancy By email or phone
      </p>
      <div>
        <button style={{border:"none"}} type="submit" className="btn5 btn-primary1">
          Submit
        </button>
      </div>
    </form>


  );
};
export default ContactUs;
