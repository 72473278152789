import React, { useRef, useState, useEffect } from "react";
import Styles from "./styles.module.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import emailjs from "@emailjs/browser";

const ContactUs = () => {
  const notify = () => toast("Thank you for your Interest.We'll reach out to you shortly.");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_hycrds9",
        "template_r979efr",
        form.current,
        "p87NmtLp7L4bobmxM"
      )
      .then(
        (result) => {
          console.log(result.text);
          notify()
          setTimeout(() => {
            window.location.href = "/"
          }, 8000);

        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <ToastContainer />
      <div className={Styles.contact_form}>
        <div className={Styles.form}>
          <label>Firstname*</label>
          <input required
            name="user_firstName" placeholder="First Name"
            className={Styles.input}></input>
        </div>
        <div className={Styles.form}>
          <label>Lastname*</label>
          <input required name="user_lastName" className={Styles.input} placeholder="Last Name"></input>
        </div>
      </div>

      <div className={Styles.contact_form}>
        <div className={Styles.form}>
          <label>Your email*</label>
          <input required
            name="user_email"
            className={Styles.input}
            placeholder="Your Email"></input>
        </div>
        <div className={Styles.form}>
          <label>Company Name*</label>
          <input required
            name="user_companyName"
            className={Styles.input}
            placeholder="Company Name"></input>
        </div>
      </div>
      <div className={Styles.contact_form}>
        <div className={Styles.formArea}>
          <label>
            Share with us what kind of website you would like to create by us.
          </label>
          <textarea required
            name="message"
            className={Styles.textarea1}
            placeholder="How can we help you?"
          ></textarea>
        </div>
      </div>
      <div>
        <button style={{ border: "none" }} type="submit" className="btn5 btn-primary1">
          Send Enquiry
        </button>

      </div>
    </form>
  );
};
export default ContactUs;
