import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Styles from "./styles.module.scss"; 
import logo from "../../assets/img/metasoft_logo.svg";
import flagIcon from "../../assets/img/uae-flag.svg";
import { Link } from 'react-router-dom';


function Header() {

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  

  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };


  
  return (
    <>
    <header className={Styles.header}> 
        <div className="container">
            <div className={Styles.headwrap}>
                <nav className={Styles.links}>
                  <NavLink to='/services' className={({ isActive }) => (isActive ? Styles.active : "")} onClick={scrollToTop}>Services</NavLink>
                  <NavLink to='/projects' className={({ isActive }) => (isActive ? Styles.active : "")} onClick={scrollToTop}>Projects</NavLink>
                  <NavLink to='/process' className={({ isActive }) => (isActive ? Styles.active : "")} onClick={scrollToTop}>Process</NavLink>
                  <NavLink to='/technology' className={({ isActive }) => (isActive ? Styles.active : "")} onClick={scrollToTop}>Technology</NavLink>
                </nav>
                <NavLink to={""} className={Styles.logo}><img src={logo} alt="Metasoft IT Solutions"/></NavLink>
                <div className={Styles.menuwrap}>
                  <img src={flagIcon} alt="uae flag"/>
                   <NavLink to={"/talk_us"}  onClick={scrollToTop} className="btn btn-small">Let's Talk</NavLink> 
                  {/* <a href="https://calendly.com/metasoftinfotech/15"  className="btn btn-small">Book Appointment</a> */}
                  <button className={Styles.menu} onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                  </button>
                </div>
            </div>
        </div>
    </header>
    <div className={openMenu ? Styles.mobileMenuOpen : Styles.mobileMenu}>
        <button className={Styles.close} onClick={toggleMenu}>close</button>
        <div className="container">
          <div className={Styles.menuWrap}>
            <div className={Styles.mainMenu}>
            <ul>
            <li> <NavLink to='/' className={({ isActive }) => (isActive ? Styles.active : "")} onClick={toggleMenu}>Home</NavLink></li>
            <li>   <NavLink to='/services' className={({ isActive }) => (isActive ? Styles.active : "")} onClick={toggleMenu}>Services</NavLink></li>
            <li>  <NavLink to='/projects' className={({ isActive }) => (isActive ? Styles.active : "")} onClick={toggleMenu}>Projects</NavLink></li>
            <li>   <NavLink to='/process' className={({ isActive }) => (isActive ? Styles.active : "")} onClick={toggleMenu}>Process</NavLink></li>
            <li>  <NavLink to='/technology' className={({ isActive }) => (isActive ? Styles.active : "")} onClick={toggleMenu}>Technology</NavLink></li>
                </ul>
            </div>
            <div className={Styles.subMenu}>
              <ul>
                <li><NavLink to={"/process"}  onClick={toggleMenu} >Process</NavLink></li>
                <li><NavLink to={"/clients"}  onClick={toggleMenu} >Clients</NavLink></li>
                <li><NavLink to={"/projects"}  onClick={toggleMenu} >Projects</NavLink></li>
                <li><NavLink to={"/talk_us"}  onClick={toggleMenu} >Careers</NavLink></li>
                <li> <NavLink to={"/technology"}  onClick={toggleMenu} >Technology</NavLink> </li>
             {/* <li> <Link to="/Talk_us">Contact Us</Link> </li> */}
             <li> <NavLink to={"/talk_us"}  onClick={toggleMenu} >Contact Us</NavLink> </li>
                <li>Metasoft 2025</li>
              </ul>
              {/* <ul>
                <li>Discover</li>
                <li>Design</li>
                <li>Build</li>
                <li>Delivery</li>
              </ul> */}
            </div>
            <div className={Styles.letsTalk}>
              <h5>Lats talk</h5>
              <h3>Lets Build a unique idea</h3>
              <p>We are a Web App design and development company that brings ideas to life through scalable, sustainable and futuristic solutions.</p>
              <NavLink to={"/talk_us"} className="btn btn-territory" onClick={toggleMenu}>Contact Us</NavLink>
            </div>
          </div>
        </div>
        <div className={Styles.socialmenu}>
            <div className="container">
              <div className={Styles.mobMenuWrap}>
                <div className={Styles.socialWrap}>
                  <Link target="_blank" to ="https://www.linkedin.com/company/31008439/admin">LinkedIn</Link>
                  <Link target="_blank" to ="https://www.instagram.com/metasoft_it/">Instagram</Link>
                  <Link target="_blank" to="https://www.facebook.com/metasoftitsolutions">Facebook</Link>
                  <Link target="_blank" to="https://twitter.com/Metasoft_it">Twitter</Link>
                  {/* <a href="#">WhatsApp</a> */}
                </div>
                <div className={Styles.locations}>
                  <span>UAE</span>
                  <span>Qatar</span>
                  <span>KSA</span>
                  <span>India</span>
                </div>
              </div>
            </div>
        </div>
    </div>
    </>
  );
}

export default Header;
