import React from "react";
import "./styles.scss";

interface Props {
  children: React.ReactNode;
}

const H2: React.FC<Props> = ({
  children,
  ...rest
}) => {
  return (
    <div className="h2">{children}</div>
  );
};

export default H2;

