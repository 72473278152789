import { Link } from "react-router-dom";
import H2 from '../../components/typography/h2';
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Styles from "./styles.module.scss";
import { InlineWidget } from "react-calendly";


function Appointment() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <section className={Styles.secMarginTop}>
            <div className="container">
            <p className="page_info_head">Let's Talk with our Expert</p>
                <div className={Styles.project_info}>
                    <H2>
                        <span>No question too big</span><span>,</span> <span> no challenge too small. </span>
                        <br/>
                        <span>Get personalized advice from </span>
                        <span> our Expert. </span>
                    </H2>
                    <p>Book your call with our experts in Arabic or English. Let's connect and help you achieve your goals!</p>
                </div>
                
            </div>
            <button className={Styles.scrollTop} onClick={scrollToTop} >Scroll Top</button>
            <div className={Styles.calendlyBox}>
                <InlineWidget url="https://calendly.com/metasoftinfotech/15?primary_color=ff2d83" />
            </div>
        </section>
    );
}
export default Appointment;