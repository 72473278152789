import React from "react";
import Styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";
import H2 from "../../components/typography/h2";
import logo3 from "../../assets/img/image_4.png";
import image from "../../assets/img/1_image.png";
import image1 from "../../assets/img/2_image.png";
import image2 from "../../assets/img/3_image.png";
import image3 from "../../assets/img/4_image.png";
import image4 from "../../assets/img/5_image.png";
import image5 from "../../assets/img/6_image.png";
import logo4 from "../../assets/img/image_build.png";
import logo5 from "../../assets/img/image_design.png";
import logo12 from "../../assets/img/image_food.png";

function Services() {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <section>
      <div className="container">
        <div className={Styles.project_info}>
          <p className="page_info_head">Services</p>
          <H2>
            <span>Crafting </span> <span>Unique Websites</span> <span> Careful, </span>
            <span> Efficient,</span> <span> and Memorable.</span> <span>Simplifying and Enhancing Your Brand Identity with </span>
            <span>Cutting-Edge Technology.</span>
          </H2>
          <p style={{ color: "#636363" }}>
            We build impeccable visual platforms that bring you more business
            and<br></br> create a unique space for you in the world of the Internet
          </p>
        </div>
        <div className={Styles.uiuxteam}>
          <div className={Styles.uiuxteam_metasoft}>
            <div className={Styles.uiuximg11}></div>
            <p className={Styles.uiuxblog}></p>
            <p className={Styles.uiuxblogtext}>
              Checklist Of Things To Have On Your Website That Signal Trust And
              Authority -
            </p>
            <p className={Styles.uiuxblogtext1}>
              Your website if done correctly acts as your 24/7 sales agent.
              However, the opposite is also true if don incorrectly. Read on to
              learn the difference.
            </p>
          </div>
          <div className={Styles.textonimage}>
            <h3 style={{ color: "white" }}>Research</h3>
            {/* <p style={{ color: "white" }}> metasoft it solutions </p> */}
          </div>
          <div className={Styles.uiuxteam_metasoft_1}>
            <div className={Styles.uiuximg21}></div>
            <p className={Styles.uiuxblog}></p>
            <p className={Styles.uiuxblogtext}>
              Why You Need A Website More Than Ever -{" "}
            </p>
            <p className={Styles.uiuxblogtext1}>
              In a post-pandemic age, the business industry has shifted and
              having an online presence is more important than ever.
            </p>
          </div>
          <div className={Styles.textonimage1}>
            <h3 style={{ color: "white" }}>Design</h3>
            {/* <p style={{ color: "white" }}> metasoft it solutions </p> */}
          </div>
          <div className={Styles.uiuxtea_mmetasoft_2}>
            <div className={Styles.uiuximg31}></div>
            <p className={Styles.uiuxblog}></p>
            <p className={Styles.uiuxblogtext}>
              The Tools I Use Everyday To Stay On Top Of Things And Keep
              Productive -
            </p>
            <p className={Styles.uiuxblogtext1}>
              As a designer who's worked remotely since 2010, one of the key
              skills I learned to be important if I wanted to be productive was
              self-management.
            </p>
          </div>
          <div className={Styles.textonimage2}>
            <h3 style={{ color: "white" }}>Build </h3>
            {/* <p style={{ color: "white" }}> metasoft it solutions </p> */}
          </div>
        </div>



        <div>

          <div className="tabStyle1">
            <Tabs>
              <TabList>
                <Tab>Research</Tab>


                <Tab>Build</Tab>
                <Tab>Design</Tab>

                <Tab>Delivery</Tab>
              </TabList>

              <TabPanel>
                <div className={Styles.titlerap}>
                  <h1 className={Styles.ok}>.</h1>

                  <h2 className={Styles.bw} >Research</h2>
                </div>
                <p className={Styles.ar}>
                  We are a UI/UX design company that brings ideas to life
                  through scalable <br></br> sustainable and futuristic design solutions.
                </p>

                <div className={Styles.ha}></div>

                <div className={Styles.marg}>
                  <div className={Styles.na}>
                    <div className={Styles.kk}>
                      <img src={image} width="30" height="30" />

                      <h2 className={Styles.sh}>Research</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image1} width="30" height="30" />
                      <h2 className={Styles.ka}>Build</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image2} width="30" height="30" />
                      <h2 className={Styles.ka}>Build</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image3} width="30" height="30" />
                      <h2 className={Styles.ka}>Design</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image4} width="30" height="30" />
                      <h2 className={Styles.ka}>Delivery</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image5} width="30" height="30" />
                      <h2 className={Styles.ka}>Hand over</h2>
                    </div>
                  </div>
                </div>

                <div className={Styles.ot}>
                  <img src={logo3} />
                  <div >
                    <h2 >Research</h2>
                    <p>
                      We are a UI/UX design <br></br>company that brings ideas<br></br>
                      to life through scalable,<br></br>sustainable and futuristic<br></br>
                      design solutions.
                    </p>
                    <p id="section-1"></p>
                    <p className={Styles.arshi} >
                      We are a UI/UX design company that brings ideas to life
                      through
                    </p>

                    <Link to={"/Talk_us"} className="btn btn-small">
                      Let's Talk
                    </Link>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className={Styles.titlerap} id="buildscroll">
                  <h1 className={Styles.ok}>.</h1>
                  <h2 className={Styles.bw} >Build</h2>
                </div>
                <p className={Styles.ar}>
                  We are a UI/UX design company that brings ideas to life
                  through scalable,<br></br> sustainable and futuristic design solutions.
                </p>

                <div className={Styles.ha}></div>
                <div className={Styles.marg}>
                  <div className={Styles.na}>
                    <div className={Styles.kk}>
                      <img src={image} width="30" height="30" />

                      <h2 className={Styles.sh}>Research</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image1} width="30" height="30" />
                      <h2 className={Styles.ka}>Build</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image2} width="30" height="30" />
                      <h2 className={Styles.ka}>Build</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image3} width="30" height="30" />
                      <h2 className={Styles.ka}>Design</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image4} width="30" height="30" />
                      <h2 className={Styles.ka}>Delivery</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image5} width="30" height="30" />
                      <h2 className={Styles.ka}>Hand over</h2>
                    </div>
                  </div>
                </div>
                <div className={Styles.ot}>
                  <img src={logo4} />
                  <div>
                    <h2 >Build</h2>
                    <p>
                      We are a UI/UX design <br></br>company that brings ideas<br></br>
                      to life through scalable,<br></br>sustainable and futuristic<br></br>
                      design solutions.
                    </p>
                    <p id="section-2"></p>
                    <p className={Styles.arshi}>
                      We are a UI/UX design company that brings ideas to life
                      through
                    </p>
                    <Link to={"/talk_us"} className="btn btn-small">
                      Let's Talk
                    </Link>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className={Styles.titlerap}>
                  <h1 className={Styles.ok}>.</h1>
                  <h2 className={Styles.bw}>Design</h2>
                </div>
                <p className={Styles.ar}>
                  We are a UI/UX design company that brings ideas to life
                  through scalable,<br></br> sustainable and futuristic design solutions.
                </p>

                <div className={Styles.ha}></div>
                <div className={Styles.marg}>
                  <div className={Styles.na}>
                    <div className={Styles.kk}>
                      <img src={image} width="30" height="30" />

                      <h2 className={Styles.sh}>Research</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image1} width="30" height="30" />
                      <h2 className={Styles.ka}>Build</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image2} width="30" height="30" />
                      <h2 className={Styles.ka}>Build</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image3} width="30" height="30" />
                      <h2 className={Styles.ka}>Design</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image4} width="30" height="30" />
                      <h2 className={Styles.ka}>Delivery</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image5} width="30" height="30" />
                      <h2 className={Styles.ka}>Hand over</h2>
                    </div>
                  </div>
                </div>
                <div className={Styles.ot}>
                  <img src={logo5} />
                  <div>
                    <h2 >Design</h2>
                    <p>
                      We are a UI/UX design <br></br>company that brings ideas<br></br>
                      to life through scalable,<br></br>sustainable and futuristic<br></br>
                      design solutions.
                    </p>

                    <p id="section-3" className={Styles.arshi} >
                      We are a UI/UX design company that brings ideas to life
                      through
                    </p>
                    <Link to={"/Talk_us"} className="btn btn-small">
                      Let's Talk
                    </Link>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className={Styles.titlerap}>
                  <h1 className={Styles.ok}>.</h1>
                  <h2 className={Styles.bw}>Delivery</h2>
                </div>
                <p className={Styles.ar}>
                  We are a UI/UX design company that brings ideas to life
                  through scalable,<br></br> sustainable and futuristic design solutions.
                </p>

                <div className={Styles.ha}></div>
                <div className={Styles.marg}>
                  <div className={Styles.na}>
                    <div className={Styles.kk}>
                      <img src={image} width="30" height="30" />

                      <h2 className={Styles.sh}>Research</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image1} width="30" height="30" />
                      <h2 className={Styles.ka}>Build</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image2} width="30" height="30" />
                      <h2 className={Styles.ka}>Build</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image3} width="30" height="30" />
                      <h2 className={Styles.ka}>Design</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image4} width="30" height="30" />
                      <h2 className={Styles.ka}>Delivery</h2>
                    </div>
                    <div className={Styles.kk}>
                      <img src={image5} width="30" height="30" />
                      <h2 className={Styles.ka}>Hand over</h2>
                    </div>
                  </div>
                </div>

                <div className={Styles.ot}>
                  <img src={logo12} />

                  <div>
                    <h2 >Delivery</h2>
                    <p>
                      We are a UI/UX design <br></br>company that brings ideas
                      to life through scalable,<br></br>sustainable and futuristic<br></br>
                      design solutions.
                    </p>
                    <p className={Styles.arshi}>
                      We are a UI/UX design company that brings ideas to life
                      through
                    </p>
                    <Link to={"/talk_us"} className="btn btn-small">
                      Let's Talk
                    </Link>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <button className={Styles.scrollTop} onClick={scrollToTop} >Scroll Top</button>
    </section>
  );
}

export default Services;
