import { Link } from "react-router-dom";
import H2 from '../../components/typography/h2';
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Styles from "./styles.module.scss";
import { InlineWidget } from "react-calendly";


function Quote() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <section className={Styles.secMarginTop}>
                <iframe className={Styles.leadForm} src="https://docs.google.com/forms/d/e/1FAIpQLSdhVicIKZ1b9mDnjUqhUL_MhAd9ehdQgpU6_EVrzs8C094mOQ/viewform?embedded=true">Loading…</iframe>
        </section>
    );
}
export default Quote;