import Styles from "./styles.module.scss";
import H2 from "../../components/typography/h2";
import { Link } from "react-router-dom";
import location from "../../assets/img/location.png";
import React, { useState } from "react";
import contact from "../../assets/img/contact.png";
import email from "../../assets/img/email.png";
import map from "../../assets/img/Rec.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";
import ContactForm from "./contactform";
import CareerForm from "./careerform";
import Map from "./map";

function Talk_us() {
  const [view, setView] = useState("Project");
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <section>
      <div className="container">
        {view === "CAREER" ? (
          <>
            <div className={Styles.Talk_us}>
              <p className="page_info_head">Let’s Connect</p>
              <H2>
                <span>Lets Build a </span> <span>unique </span>{" "}
                <span> idea</span>
              </H2>
              <p className={Styles.Talk_us_text}>
                <p>
                  We are a UI/UX design company that brings ideas to life
                  through scalable, sustainable and futuristic design solutions.
                </p>
              </p>

              {/* <div className={Styles.Talk_us_talk1_new}>
                <p
                  className={Styles.Talk_us_text}
                  onClick={() => setView("PROJECT")}
                >
                  <span className={Styles.span}>Lets talk </span>
                  Project
                </p>
              </div>
              <div className={Styles.Talk_us_talk_new}>
                <p
                  className={Styles.Talk_us_text}
                  onClick={() => setView("CAREER")} >
                  <span className={Styles.span}>Join Us </span>
                  <br></br> Jobs / Careers
                </p>
              </div> */}
            </div>

            <div>
              <div className={Styles.Talk1}>
                <p style={{ textAlign: "left" }}>How can reach us?</p>
              </div>
              <CareerForm />
            </div>
          </>
        ) : (
          <>
            <div className={Styles.Talk_us}>
              <p className="page_info_head">Let’s Connect</p>
              <H2>
                <span>Lets Build a </span> <span>unique </span>{" "}
                <span> idea</span>
              </H2>
              {/* <p>
                  We can sit and explore all things that can be done to
                  develop a stunning website for your success.{" "}
                </p> */}

              {/* <div className={Styles.Talk_us_talk}>
                <p
                  className={Styles.Talk_us_text}
                  onClick={() => setView("PROJECT")} style={{
                    fontSize: "30px"
                  }}
                >
                  <span className={Styles.span} style={{
                    fontSize: "18px"
                  }}>Lets talk </span>
                  Project
                </p>
              </div> */}
              {/* <div className={Styles.Talk_us_talk1}>
                <p
                  className={Styles.Talk_us_text}
                  onClick={() => setView("CAREER")} style={{
                    fontSize: "30px", width: "350px"
                  }}
                >
                  {" "}
                  <span className={Styles.span} style={{
                    fontSize: "18px"
                  }}>Join Us </span>
                  <br></br>Jobs / Careers
                </p>
              </div> */}
            </div>

            <div>
              {/* <div className={Styles.Talk}>
                <p>You Can Talk Through</p>
              </div> */}
              <div className={Styles.Talk_through_contact}>
                <div className="">
                  <div className="">
                    <div className={Styles.Talk_throughWrap}>
                      <div>
                        <div className={Styles.Talk_through}>
                          <p className={Styles.Talk_through_text}>
                            <img src={location}></img>
                          </p>

                          <div className={Styles.Talk_through_1}>
                            <h3>UAE</h3>
                            <p className={Styles.Talk_through_text1}>
                              Office Number 216A - Al Ittihad Square
                              <br />
                              Al Ittihad Road , Dubai - UAE
                              <br />
                              +971 43 99 1704 | +971 53 63704
                              <br />
                              <a href="mailto:info@metasoft.ae">
                                admin@metasoft.ae
                              </a>{" "}
                              |{" "}
                              <a href="mailto:sales@metasoftit.com">
                                sales@metasoft.ae
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="">
                          <iframe
                            className=""
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.9260675678584!2d55.34534707506697!3d25.273072377662242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d8fcde5080d%3A0xa103a2b269d59102!2sMetasoft%20IT%20Solutions!5e0!3m2!1sen!2sin!4v1684144026394!5m2!1sen!2sin"
                          ></iframe>
                        </div>
                      </div>
                      <div>
                        <div className={Styles.Talk_through}>
                          <p className={Styles.Talk_through_text}>
                            <img src={location}></img>
                          </p>
                          <div className={Styles.Talk_through_1}>
                            <h3>India</h3>
                            <p className={Styles.Talk_through_text1}>
                              3rd Floor, Excel Mischief Mall
                              <br />
                              Mangaluru Karnataka – INDIA
                              <br />
                              +91 7022 821 888 | +91 87149 46704
                              <br />
                              <a href="mailto:sales@metasoft.ae">
                                info@metasoftit.com
                              </a>{" "}
                              |{" "}
                              <a href="mailto:sales@metasoft.ae">
                                sales@metasoftit.com
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="">
                          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.58944799842!2d74.83892807454504!3d12.869772617103715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba35b2a803032a7%3A0x800ff62fd3f7dd95!2sMetasoft%20IT%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1714205561483!5m2!1sen!2sin"></iframe>
                        </div>
                      </div>
                    </div>
                    {/* <div className={Styles.Talk_through}>
                      <p className={Styles.Talk_through_text}>
                        <img src={contact}></img>
                      </p>
                      <div className={Styles.Talk_through_1}>
                        <p className={Styles.Talk_through_text_1}>
                          HELPLINE
                          <br />
                          ENQUIRIE
                        </p>
                      </div>
                      <div className={Styles.Talk_through_2}>
                        <p className={Styles.Talk_through_text_1}>
                          (+971) 43 99 1704
                          <br />
                          (+971) 56 116 3704 <br />
                          (+971) 50 536 3704
                        </p>
                      </div>
                    </div> */}
                    {/* <div className={Styles.Talk_through}>
                      <p className={Styles.Talk_through_text}>
                        <img src={email}></img>
                      </p>
                      <div className={Styles.Talk_through_1}>
                        <p className={Styles.Talk_through_text_1}>
                          <a href="mailto:info@metasoft.ae">admin@metasoft.ae</a>
                          <br />
                          <a href="mailto:sales@metasoftit.com">
                          sales@metasoft.ae
                          </a>
                         
                        </p>
                      </div>
                      
                  
                    
                      <p className={Styles.Talk_through_text}>
                        <img src={email}></img>
                      </p>
                      <div className={Styles.Talk_through_1}>
                        <p className={Styles.Talk_through_text_1}>
              
                          <a href="mailto:sales@metasoft.ae">
                          info@metasoftit.com  
                          </a>
                          <br/>
                          <a href="mailto:sales@metasoft.ae">
                          sales@metasoftit.com
                          </a>
                        </p>
                      </div>
                      
                    </div> */}
                  </div>
                </div>
              </div>
              <div className={Styles.Talk1}>
                <p className="">How can reach us?</p>
              </div>
              <ContactForm />
            </div>
          </>
        )}
      </div>
      <button className={Styles.scrollTop} onClick={scrollToTop}>
        Scroll Top
      </button>
    </section>
  );
}

export default Talk_us;
