import React from 'react';

import Styles from "./styles.module.scss";
import Slider from "react-slick";
import client1 from "../../assets/img/client1.png";
import client2 from "../../assets/img/client2.png";
import client3 from "../../assets/img/client3.png";
import client4 from "../../assets/img/client4.png";
import client5 from "../../assets/img/scentabook.png";
import client6 from "../../assets/img/360viewlogo.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function SimpleSlider() {

  const settings = {
    loop: true,
    center: true,
    slidesToShow: 4,
    margin: 0,
    autoplay: false,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (

    <Slider {...settings} className={Styles.slider_wrap}>
      <div className={Styles.slider}>
        
        <img src={client1}></img>
      </div>
      <div className={Styles.slider}>
        <img src={client2}></img>
      </div>
      <div className={Styles.slider}>
        <img src={client3}></img>
      </div>
      <div className={Styles.slider}>
        <img src={client4}></img>
      </div>
      <div className={Styles.slider}>
        <img src={client5} className={Styles.clinetimg1}></img>
      </div>
      <div className={Styles.slider}>
        <img src={client6} className={Styles.clinetimg2}></img>
      </div>
      <div className={Styles.slider}>
        <img src={client1}></img>
      </div>
      <div className={Styles.slider}>
        <img src={client2}></img>
      </div>
      <div className={Styles.slider}>
        <img src={client3}></img>
      </div>
      <div className={Styles.slider}>
        <img src={client4}></img>
      </div>
      <div className={Styles.slider}>
        <img src={client5} className={Styles.clinetimg1}></img>
      </div>
      <div className={Styles.slider}>
        <img src={client6} className={Styles.clinetimg2}></img>
      </div>
    </Slider>

  );
}
