import { Link } from "react-router-dom";
import H2 from '../../components/typography/h2';
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Styles from "./styles.module.scss";
import OurClients from "../../components/clients";
import wifi from '../../assets/img/wifi.png'
import case_studies from '../../assets/img/case_studies.png'
import amberaroma from '../../assets/img/amberaroma.png'
import hostec from '../../assets/img/hostec.png'
import oncloudwifi from '../../assets/img/oncloudwifi.png'
import sentabook from '../../assets/img/sentabook.png'
import project369view from '../../assets/img/360view.png'
import uniwell from '../../assets/img/uniwell.png'
import zeeqr  from '../../assets/img/zeeqrbg.png'
import programmingimg1 from '../../assets/img/programmingimg1.png'
import programmingimg2 from '../../assets/img/programmingimg2.png'
import programmingimg3 from '../../assets/img/programmingimg3.png'
import programmingimg4 from '../../assets/img/programmingimg4.png'
import programmingimg5 from '../../assets/img/programmingimg5.png'
import programmingimg6 from '../../assets/img/programmingimg6.png'
import programmingimg7 from '../../assets/img/programmingimg7.png'
import programmingimg8 from '../../assets/img/programmingimg8.png'
import programmingimg9 from '../../assets/img/programmingimg9.png'
import programmingimg10 from '../../assets/img/programmingimg10.png'
import programmingimg11 from '../../assets/img/programmingimg11.png'
import programmingimg12 from '../../assets/img/programmingimg12.png'
import programmingimg13 from '../../assets/img/programmingimg14.png'
import programmingimg14 from '../../assets/img/programmingimg15.png'
import programmingimg15 from '../../assets/img/programmingimg16.png'
import programmingimg16 from '../../assets/img/programmingimg17.png'
import { ButtonComponent } from '@syncfusion/ej2-react-buttons'
function Projects() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <section>
            <div className="container">
            <p className="page_info_head">Projects</p>
                <div className={Styles.project_info}>
                    <H2>
                        <span>Explore a rich collection of </span> <span>innovative projects.</span> <span> Unlock Your Online Potential: </span>
                        <span>Elevate Your Success with a </span>
                        <span>High-Performing Website.</span>
                    </H2>
                    <p>Elevate Your Online Presence: Let Our Proven Visual Platform Models Make Your Next Project a Stunning Success.</p>
                </div>
                <div className={Styles.Project_img}>
                    <img className={Styles.projectimglink} src={sentabook} />
                </div>
                <div className={Styles.website_details}>

                    <div className={Styles.website_img}>
                        <a href="https://scentabook.com/" target="_blank">
                            <img src={wifi} />
                            <p>Live website</p>
                        </a>
                    </div>
                    <div className={Styles.website_img}>
                        <img src={case_studies}></img>
                        <p>Case Studies</p>
                    </div>
                </div>
                <div className={Styles.Project_img}>
                    <img className={Styles.projectimglink} src={project369view} />
                </div>
                <div className={Styles.website_details}>

                    <div className={Styles.website_img}>
                        <a href="https://360view.ai/" target="_blank">
                            <img src={wifi} />
                            <p>Live website</p>
                        </a>
                    </div>
                    <div className={Styles.website_img}>
                        <img src={case_studies}></img>
                        <p>Case Studies</p>
                    </div>
                </div>
                <div className={Styles.Project_img}>
                    <img className={Styles.projectimglink} src={zeeqr} />
                </div>
                <div className={Styles.website_details}>
                    <div className={Styles.website_img}>
                        <a href="https://zeeqr.com/" target="_blank">
                            <img src={wifi} />
                            <p>Live website</p>
                        </a>
                    </div>
                    <div className={Styles.website_img}>
                        <img src={case_studies}></img>
                        <p>Case Studies</p>
                    </div>
                </div>
                <div className={Styles.Project_img}>
                    <img src={hostec} />
                </div>
                <div className={Styles.website_details}>

                    <div className={Styles.website_img}>
                        <a href="https://hostec.ae/" target="_blank">
                            <img src={wifi} />
                            <p>Live website</p>
                        </a>
                    </div>
                    <div className={Styles.website_img}>
                        <img src={case_studies}></img>
                        <p>Case Studies</p>
                    </div>
                </div>
                <div className={Styles.Project_img}>
                    <img src={amberaroma} />
                </div>
                <div className={Styles.website_details}>
                    <div className={Styles.website_img}>
                        <a href="https://amberaroma.com/" target="_blank">
                            <img src={wifi} />
                            <p>Live website</p>
                        </a>
                    </div>
                    <div className={Styles.website_img}>
                        <img src={case_studies}></img>
                        <p>Case Studies</p>
                    </div>
                </div>
               
           
                <div className={Styles.Project_img}>
                    <img src={oncloudwifi} />
                </div>
                <div className={Styles.website_details}>

                    <div className={Styles.website_img}>
                        <a href="https://oncloudwifi.com/" target="_blank">
                            <img src={wifi} />
                            <p>Live website</p>
                        </a>
                    </div>
                    <div className={Styles.website_img}>
                        <img src={case_studies}></img>
                        <p>Case Studies</p>
                    </div>
                </div>
                <div className={Styles.Project_img}>
                    <img className={Styles.projectimglink} src={uniwell} />
                </div>
                <div className={Styles.website_details}>

                    <div className={Styles.website_img}>
                        <a href="https://uniwell.ae/" target="_blank">
                            <img src={wifi} />
                            <p>Live website</p>
                        </a>
                    </div>
                    <div className={Styles.website_img}>
                        <img src={case_studies}></img>
                        <p>Case Studies</p>
                    </div>
                </div>
                
               
                <div className={Styles.clients} >
                    <div>
                        <p className={Styles.cleints_text}>
                            Our Esteemed Clientele
                        </p>
                        <p>
                            Join our winning team of satisfied clients
                        </p>
                    </div>

                    <OurClients />
                </div>
                <div className={Styles.programming_language_text}>
                    <p className={Styles.programming_language_text_p}>Latest Technologies</p>
                    <p>Empower your business with cutting-edge tech. </p>
                </div>
                <div className={Styles.programming_language}>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg1}></img>
                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg2}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg3}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg4}></img>

                    </div>
                </div>
                <div className={Styles.programming_language}>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg5}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg6}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg7}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg8}></img>

                    </div>
                </div>
                <div className={Styles.programming_language}>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg9}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg10}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg11}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg12}></img>

                    </div>
                </div>
                <div className={Styles.programming_language}>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg13}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg14}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg15}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg16}></img>

                    </div>
                </div>
            </div>
            <button className={Styles.scrollTop} onClick={scrollToTop} >Scroll Top</button>
        </section>
    );
}
export default Projects;