import React from "react";
import { NavLink } from "react-router-dom";
import Styles from "./styles.module.scss";
import logo from "../../assets/img/metasoft_logo.svg";
import { Icon } from '@iconify/react';
import { Link } from "react-router-dom";



function Footer() {
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  
  
  return (
    <footer className={Styles.footer}>
        <div className="container">
          <div className={Styles.getConect}>
            <p>Want to Implement Your Business Ideas?</p>
            <h3>Let’s Get Started</h3>
            <NavLink to={"/talk_us"} onClick={scrollToTop} className="btn btn-primary">Let's Talk</NavLink>
          </div>
          <div className={Styles.copyright}>
            <div className={Styles.logo}>
                <img src={logo} alt="metasoft"/>
            </div>
            <div className={Styles.social}>
              <Link target="_blank" to="https://twitter.com/home"><Icon icon="mdi:twitter" /></Link>
              <Link target="_blank" to="https://www.facebook.com/metasoftitsolutions"><Icon icon="jam:facebook" /></Link>
              <Link target="_blank" to ="https://www.instagram.com/metasoft_it/"><Icon icon="ant-design:instagram-filled" /></Link>
              <Link target="_blank" to ="https://www.linkedin.com/company/31008439/admin"><Icon icon="ci:linkedin" /></Link>
            </div>
          </div>
          <div className={Styles.contact}>
            <div>
              <h4>Developed by?</h4>
              <p>Metasoft Web Development Team</p>
            </div>
            <div>
              <h4>Say hi!</h4>
              <p>
                <a href="mailto:sales@metasoft.ae">sales@metasoft.ae</a>
                </p>
            </div>
            <div>
              <h4>Legal stuff</h4>
              <p>Privacy & policy</p>
            </div>
          </div>
        </div>
       
    </footer>
    
  );
}

export default Footer;
