import React from "react";
import { Link } from "react-router-dom";
import H2 from '../../components/typography/h2';

import { NavLink } from "react-router-dom";
import Styles from "./styles.module.scss";
import oncloudwifi from '../../assets/img/OnCloud Wifi.png'
import programmingimg1 from '../../assets/img/programmingimg1.png'
import programmingimg2 from '../../assets/img/programmingimg2.png'
import programmingimg3 from '../../assets/img/programmingimg3.png'
import programmingimg4 from '../../assets/img/programmingimg4.png'
import programmingimg5 from '../../assets/img/programmingimg5.png'
import programmingimg6 from '../../assets/img/programmingimg6.png'
import programmingimg7 from '../../assets/img/programmingimg7.png'
import programmingimg8 from '../../assets/img/programmingimg8.png'
import programmingimg9 from '../../assets/img/programmingimg9.png'
import programmingimg10 from '../../assets/img/programmingimg10.png'
import programmingimg11 from '../../assets/img/programmingimg11.png'
import programmingimg12 from '../../assets/img/programmingimg12.png'
import programmingimg13 from '../../assets/img/programmingimg14.png'
import programmingimg14 from '../../assets/img/programmingimg15.png'
import programmingimg15 from '../../assets/img/programmingimg16.png'
import programmingimg16 from '../../assets/img/programmingimg17.png'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import type { ReactTabsFunctionComponent, TabProps } from 'react-tabs';
import process from '../../assets/img/process.png';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';

function Process() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (

        <section>
            <div className="container">
                <p className="page_info_head">Process</p>
                <div className={Styles.project_info}>
                    <H2>
                        <span>Concept to Launch: </span> <span>Our Comprehensive </span> <span> Web Development </span>
                        <span>Process Explain.</span>
                    </H2>
                    <p>Our streamlined process ensures a successful website launch, from concept to execution.</p>
                </div>
                <div className={Styles.Project_img}>
                    <img src={process} />
                    <p>Dedicated website for corporate</p>
                </div>
                <div className={Styles.process_text}>
                    <p className={Styles.process_text_1}>
                        We transform abstract ideas
                        into concrete design solutions
                    </p>
                    <p className={Styles.process_text_2}>Dedicated website for corporate</p>
                </div>

                <div className={Styles.project_deadline}>
                    <div className={Styles.project_deadline_1}>
                        <p className={Styles.project_deadline_text1}>1. Project Planning and Analysis</p>
                    </div>
                    <div className={Styles.project_deadline_1}>
                        <p className={Styles.project_deadline_text1}>2. Information Architecture and Wireframing</p>
                    </div>
                    <div className={Styles.project_deadline_1}>
                        <p className={Styles.project_deadline_text1}>3. Design</p>
                    </div>
                    <div className={Styles.project_deadline_1}>
                        <p className={Styles.project_deadline_text1}>4. Front-end Development</p>
                    </div>
                    <div className={Styles.project_deadline_1}>
                        <p className={Styles.project_deadline_text1}>5. Back-end Development</p>
                    </div>
                    <div className={Styles.project_deadline_1}>
                        <p className={Styles.project_deadline_text1}>6. Content Creation and Integration</p>
                    </div>
                    <div className={Styles.project_deadline_1}>
                        <p className={Styles.project_deadline_text1}>7. Testing and Quality Assurance</p>
                    </div>
                    <div className={Styles.project_deadline_1}>
                        <p className={Styles.project_deadline_text1}>8. Deployment</p>
                    </div>
                    <div className={Styles.project_deadline_1}>
                        <p className={Styles.project_deadline_text1}>9. Maintenance and Updates</p>
                    </div>
                </div>



                <div className={Styles.programming_language_text}>
                    <p className={Styles.programming_language_text_p}>Latest Technologies</p>
                    <p>Empower your business with cutting-edge tech. </p>
                </div>
                <div className={Styles.programming_language}>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg1}></img>
                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg2}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg3}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg4}></img>

                    </div>
                </div>
                <div className={Styles.programming_language}>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg5}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg6}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg7}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg8}></img>

                    </div>
                </div>
                <div className={Styles.programming_language}>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg9}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg10}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg11}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg12}></img>

                    </div>
                </div>
                <div className={Styles.programming_language}>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg13}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg14}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg15}></img>

                    </div>
                    <div className={Styles.programming_language_img}>
                        <img src={programmingimg16}></img>

                    </div>
                </div>
            </div>
            <div className={Styles.futureplan}>
                <div className="container">
                    <div className={Styles.futuretechnology}>
                        <div className={Styles.text}>
                            <p>Steps for Work</p>
                        </div>
                        <div className={Styles.artificial}>
                            <p className={Styles.text2}>Discover</p>
                            <p className={Styles.text3}>
                                we are a UI UX design company that
                                brings ideas to life through scalable,
                                sustainable and futuristic design
                            </p>
                        </div>
                    </div>
                    <div className={Styles.virtualreality}>
                        <p className={Styles.virtualrealitytext}>virtual
                            reality (VR)</p><p className={Styles.virtualrealitytext1}>technology elevates website development by enabling immersive and interactive experiences that engage users and bring products and services to life in a unique and captivating way.</p>
                    </div>
                    <div className={Styles.internetofthings}>
                        <p className={Styles.internetofthingstext}>internet of
                            things (IOT)</p><p className={Styles.internetofthingstext1}>The Internet of Things (IoT) revolutionizes website development by allowing for seamless integration with smart devices, creating new opportunities for automation, data collection, and personalized user experiences.</p>
                    </div>
                    <div className={Styles.automation}>
                        <p className={Styles.automationtext}>automation</p><p className={Styles.automationtext1}>Automation technology streamlines website development by automating routine tasks, reducing errors, and increasing efficiency, freeing up valuable time for more strategic and creative work.</p>
                    </div>
                </div>
            </div>
            <div>
                <div className={Styles.caseBg}>
                    <div className="container">
                        <div className="tabStyle1">
                            <Tabs>
                                <TabList>
                                    <Tab>Discover</Tab>

                                    <Tab>Design</Tab>
                                    <Tab>Build</Tab>

                                    <Tab>Delivery</Tab>
                                </TabList>
                                <TabPanel>
                                    <div className={Styles.step1}>
                                        <p className={Styles.process_step1}>Step 01</p>
                                    </div>
                                    <div className={Styles.process_discover}>

                                        <p className={Styles.process_descover_text}>Discover
                                            <p className={Styles.process_descover_text_p}>We do the thorough research that is necessary to identify all the essential elements that can contribute to creating a reliable, elegant, and interactive website, requiring a high level of focus and attention to detail.</p></p>

                                        <div className={Styles.process_descover_01}>

                                            <div className={Styles.process_descover_1_number}>
                                                <p className={Styles.process_descover_1_number_1}>01.</p>
                                                <p className={Styles.process_descover_text1}>Recongnising the audience</p></div>
                                            <p className={Styles.process_descover_text2}>Who is the intended audience for the website? What are their interests, needs, and expectations? Understanding your target audience is crucial for designing a website that meets their needs and provides a positive user experience.</p>

                                            <div className={Styles.process_descover_1_number}>
                                                <p className={Styles.process_descover_1_number_1}>02.</p>
                                                <p className={Styles.process_descover_text1}>Understanding the established</p></div>
                                            <p className={Styles.process_descover_text2}>Who are your competitors, and what are they doing well? By researching your competitors, you can identify gaps in the market and design a website that stands out from the crowd.</p>
                                            <div className={Styles.process_descover_1_number}>
                                                <p className={Styles.process_descover_1_number_1}>03.</p>
                                                <p className={Styles.process_descover_text1}>Visualising the destination: </p></div>
                                            <p className={Styles.process_descover_text2}>What are the goals of the website? Is it to generate leads, sell products, provide information, or something else? Defining the goals of the website is crucial for designing a site that meets those goals.</p>
                                        </div>
                                    </div>

                                </TabPanel>
                                <TabPanel>
                                    <div className={Styles.step1}>
                                        <p className={Styles.process_step1}>Step 02</p>
                                    </div>
                                    <div className={Styles.process_discover}>
                                        <p className={Styles.process_descover_text}>Design
                                            <p className={Styles.process_descover_text_p}>What are the goals of the website? Is it to generate leads, sell products, provide information, or something else? Defining the goals of the website is crucial for designing a site that meets those goals</p></p>
                                        <div className={Styles.process_descover_01}>
                                            <div className={Styles.process_descover_1_number}>
                                                <p className={Styles.process_descover_1_number_1}>01.</p>
                                                <p className={Styles.process_descover_text1}>Making an impact</p> </div>
                                            <p className={Styles.process_descover_text2}>A website is often the first point of contact between a business and its potential customers. A well-designed website can make a positive first impression and help establish trust and credibility with visitors.</p>

                                            <div className={Styles.process_descover_1_number}>
                                                <p className={Styles.process_descover_1_number_1}>02.</p>
                                                <p className={Styles.process_descover_text1}>Building an identity </p></div>
                                            <p className={Styles.process_descover_text2}>A website design should reflect the brand identity and values of a business. Consistent branding across all aspects of the website, including color scheme, typography, and imagery, can help reinforce the brand's identity and create a memorable user experience.</p>

                                            <div className={Styles.process_descover_1_number}>
                                                <p className={Styles.process_descover_1_number_1}>03.</p>
                                                <p className={Styles.process_descover_text1}>Interacting</p> </div>
                                            <p className={Styles.process_descover_text2}>A well-designed website should provide a seamless user experience that is intuitive, easy to navigate, and visually appealing. A positive user experience can increase engagement and encourage visitors to return to the site in the future.</p>

                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className={Styles.step1}>
                                        <p className={Styles.process_step1}>Step 03</p>
                                    </div>
                                    <div className={Styles.process_discover}>
                                        <div className={Styles.process_descover_text}>Development
                                            <p className={Styles.process_descover_text_p}>
                                                Creating a stunning website that incorporates the latest technology is a
                                                skill that varies depending on the type of website being developed, as it requires a tailored approach.
                                            </p>
                                        </div>
                                        <div className={Styles.process_descover}>
                                            <div className={Styles.process_descover_1_number}>
                                                <p className={Styles.process_descover_1_number_1}>01.</p>
                                                <p className={Styles.process_descover_text1}>Identifying the opportunity</p></div>
                                            <p className={Styles.process_descover_text2}>The first step in building a website is to define the project scope. This involves identifying the purpose of the website, the target audience, and the features and functionality required for the site.</p>

                                            <div className={Styles.process_descover_1_number}>
                                                <p className={Styles.process_descover_1_number_1}>02.</p>
                                                <p className={Styles.process_descover_text1}>Wireframing</p></div>
                                            <p className={Styles.process_descover_text2}>The next step is to plan the structure of the website. This involves creating a site map that outlines the different pages and sections of the site, as well as the navigation hierarchy.</p>
                                            <div className={Styles.process_descover_1_number}>
                                                <p className={Styles.process_descover_1_number_1}>03.</p>
                                                <p className={Styles.process_descover_text1}>Choosing the platform</p></div>
                                            <p className={Styles.process_descover_text2}>With the site structure and design concept in place, the next step is to develop the website. This involves selecting a platform, such as WordPress or Shopify, and building the site using HTML, CSS, and JavaScript.</p>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className={Styles.step1}>
                                        <p className={Styles.process_step1}>Step 04</p>
                                    </div>
                                    <div className={Styles.process_discover}>

                                        <p className={Styles.process_descover_text}>Delivery
                                            <p className={Styles.process_descover_text_p}>Once the website is created, our team takes great care in ensuring that it is ready for public release, going through a series of relevant steps to ensure accuracy, and eventually launching it to a global audience with pride.</p></p>

                                        <div className={Styles.process_descover}>

                                            <div className={Styles.process_descover_1_number}>
                                                <p className={Styles.process_descover_1_number_1}>01.</p>
                                                <p className={Styles.process_descover_text1}>Domain Registration</p></div>
                                            <p className={Styles.process_descover_text2}>Before a website can be delivered, a domain name must be registered. This is the web address that users will use to access the website (e.g., www.example.com). A domain name can be registered through a domain registrar, and it typically requires an annual fee.</p>

                                            <div className={Styles.process_descover_1_number}>
                                                <p className={Styles.process_descover_1_number_1}>02.</p>
                                                <p className={Styles.process_descover_text1}>Hosting Setup</p></div>
                                            <p className={Styles.process_descover_text2}>A website must be hosted on a web server so that it can be accessed by users. Hosting setup involves selecting a hosting provider, choosing a hosting plan that suits the website's needs, and configuring the hosting account.</p>
                                            <div className={Styles.process_descover_1_number}>
                                                <p className={Styles.process_descover_1_number_1}>03.</p>
                                                <p className={Styles.process_descover_text1}>Website Migration</p></div>
                                            <p className={Styles.process_descover_text2}>If the website has been developed locally, it needs to be migrated to the hosting server. This involves transferring the website files, database, and any other necessary components to the web server.</p>
                                        </div>
                                    </div>


                                </TabPanel>

                            </Tabs>

                        </div>
                    </div></div>

                    
            </div>
            <div className="container">
            <div className={Styles.process_text}>
                    <p className={Styles.process_text_1}>
                        We transform abstract ideas
                        into concrete design solutions
                    </p>
                    <p className={Styles.process_text_2}>Dedicated website for corporate</p>
                    <div className={Styles.btntext}>

                    <Link to={"/talk_us"} className="btn btn-small" >Lets talk</Link>
                </div>
                </div>
                
            </div>
            <button className={Styles.scrollTop} onClick={scrollToTop} >Scroll Top</button>
        </section>
    );
}

export default Process;