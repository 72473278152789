import React from 'react'; 
import './App.css';
import { Routes, Route, HashRouter } from 'react-router-dom';   
import Header from './components/header'; 
import Footer from './components/footer';
import Home from './pages/home';
import Services from './pages/services';
import Projects from './pages/projects';
import Process from './pages/process';
import Technology from './pages/technologys';
import Talkus from './pages/talk2us';
import Appointment from './pages/appointment';
import Quote from './pages/freequote';
  
function App() {
  return (
    <HashRouter>
        <Header/> 
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/services' element={<Services />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/process' element={<Process />} />
            <Route path='/technology' element={<Technology/>} />
            <Route path='/talk_us' element={<Talkus />} />
            <Route path='/appointment' element={<Appointment />} />
            <Route path='/free_quote' element={<Quote />} />
          </Routes>
        <Footer/>
    </HashRouter>
  );
}

export default App;
