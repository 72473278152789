import React, { Component, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Styles from "./styles.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import H1 from '../../components/typography/h1';
import H2 from '../../components/typography2/h2';
import homeimg1 from '../../assets/img/homeimg1.png';
import homeimg2 from '../../assets/img/homeimg2.png';
import playicon from '../../assets/img/play_button.png'
import search from '../../assets/img/search.png'
import build from '../../assets/img/build.png'
import uiux from '../../assets/img/uiux.png'
import quote1 from '../../assets/img/1.png'
import quote2 from '../../assets/img/2.png'
import delivery from '../../assets/img/delivery.png'
import uiuximg1 from '../../assets/img/ui-ux img1.png'
import uiuximg2 from '../../assets/img/ui-ux img2.png'
import uiuximg3 from '../../assets/img/ui-ux img3.png'
import componentimg from '../../assets/img/Component.png'
function Home() {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const settings = {
    loop: true,
    center: true,
    margin: 0,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  return (
    <section>
      <div className="container">
        <H1>
          <span>Building </span> <span>Stunning</span> <span> Websites </span>
          <span> with Ease Using Advanced Technology.</span> <span> Develop</span> <span>the most exciting website  </span>
          <span>for you Now!</span>
        </H1>
        <div className={Styles.companyInfo}>
          <div>
            <h2>One Million+</h2>
            <p>Lives Touched</p>
          </div>
          <div>
            <h2>03+</h2>
            <p>Branches</p>
          </div>
          <div>
            <h2>128+</h2>
            <p>Projects</p>
          </div>
        </div>
        <div className={Styles.companywebsite}>
          <div className={Styles.companywebsite_img1}>
            {/* <img className="img-fluid " src={homeimg1} /> */}
            <video className="video" autoPlay muted loop playsInline>
              <source src="https://s3-metasoft.s3.us-east-2.amazonaws.com/metasoft_home_1.mp4" type="video/mp4" />
              <source src="https://s3-metasoft.s3.us-east-2.amazonaws.com/metasoft_home_1.webm" type="video/webm" />
            </video>
          </div>
          <div className={Styles.companywebsite_img2}>
            {/* <img className="img-fluid" src={homeimg2} /> */}
            <video className="video" autoPlay muted loop playsInline>
              <source src="https://s3-metasoft.s3.us-east-2.amazonaws.com/metasoft_home_2.mp4" type="video/mp4" />
              <source src="https://s3-metasoft.s3.us-east-2.amazonaws.com/metasoft_home_2.webm" type="video/webm" />
            </video>
          </div>
        </div>
        <div className={Styles.companywebsite_text}>
          <div className={Styles.companywebsite_text_1}>
            From Concept to Launch: Our Comprehensive Web Development Process Explained.
            <div className={Styles.hyper_line}>
              <H1>
              </H1></div>
            <div className={Styles.companywebsite_text_4}>
              Our streamlined process ensures a successful website launch, from concept to execution.
            </div></div>
          <div className={Styles.companywebsite_text_2}>
            When I say high-performing website, I don’t just
            mean a beautiful looking website;
            <div className={Styles.companywebsite_text_3}>
              Our comprehensive web development process covers everything from initial concept and design to final testing and launch, ensuring a seamless and successful website development experience for our clients.

            </div>
            <Link to={"/talk_us"} className="btn btn-small" >Lets talk</Link>
          </div>
        </div>
        <div className={Styles.ourVideo}>
          <a className={Styles.video}>
            <span></span>
          </a>

          <video className="video" autoPlay muted loop playsInline>
            <source src="https://s3-metasoft.s3.us-east-2.amazonaws.com/web_developement.mp4" type="video/mp4" />
            <source src="https://s3-metasoft.s3.us-east-2.amazonaws.com/web_developement.webm" type="video/webm" />
          </video>
          {/* <img className="img-fluid" src={video} /> */}

        </div>
        <div className={Styles.text}>
          <p>Crafting a one-of-a-kind website with care and efficiency for you.</p>
          <div className={Styles.text1}>
            <p>We construct your visual platform with cutting-edge technology</p></div>
        </div>
        <div className={Styles.researchbuild}>
          <div className={Styles.researchbuild_1}>
            <div className={Styles.researchbuild_text_1} >
              <img src={search} className={Styles.img} />
              <h2 className={Styles.Researchtext}>Research</h2>
            </div>
            <p className={Styles.Researchtext_1}>A process that requires high focus to list out all elements that could make your website reliable, elegant, and interactive. </p>
            <div className={Styles.researchbuild_text_1} >
              <img src={uiux} className={Styles.img} />
              <h2 className={Styles.Researchtext}>Build</h2>
            </div>
            <p className={Styles.Researchtext_1}>Building an impressive webspace embedded with the latest technology is an art. And this process is subjective to the type of which kind of website being built.</p>
          </div>
          <div className={Styles.researchbuild_1}>
            <div className={Styles.researchbuild_text_1} >
              <img src={build} className={Styles.img} />
              <h2 className={Styles.Researchtext}>Design</h2>
            </div>
            <p className={Styles.Researchtext_1}>Design is a vital element that can significantly impact user experience, engagement, and brand perception. </p>
            <div className={Styles.researchbuild_text_1} >
              <img src={delivery} className={Styles.img} />
              <h2 className={Styles.Researchtext}>Delivery</h2>
            </div>
            <p className={Styles.Researchtext_1}>The crafted website is published to the public with pride. This process involves some relevant actions which are accurately maintained by our team. And it is launched globally. </p>
          </div>
        </div>
        <div className={Styles.researchbuild}>
          <div className={Styles.researchbuild_1}>
            <p className={Styles.Researchtext_1}>
              <Link to={"/talk_us"} className="btn btn-small" >Lets talk</Link>
            </p>
          </div>
        </div>
      </div>
      <div className={Styles.futureplan}>
        <div className="container">
          <div className={Styles.futuretechnology}>
            <div className={Styles.text}>
              <p>Metasoft It Solutions future technology<span className={Styles.year}>2025</span></p>
              <p className={Styles.textLine}>
              </p>
              <p className={Styles.para}>We are a UI UX design company that brings ideas to life through scalable, sustainable and futuristic design solutions.</p>
            </div>
            <div className={Styles.artificial}>
              <p className={Styles.text2}>Artificial Intelligence</p><p className={Styles.text3}>technology enhances website development by providing advanced analytics, personalized user experiences, and automating complex tasks for more efficient and effective results.</p>
            </div>
          </div>
          <div className={Styles.virtualreality}>
            <p className={Styles.virtualrealitytext}>virtual
              reality (VR)</p><p className={Styles.virtualrealitytext1}>technology elevates website development by enabling immersive and interactive experiences that engage users and bring products and services to life in a unique and captivating way.</p>
          </div>
          <div className={Styles.internetofthings}>
            <p className={Styles.internetofthingstext}>internet of
              things (IOT)</p><p className={Styles.internetofthingstext1}>The Internet of Things (IoT) revolutionizes website development by allowing for seamless integration with smart devices, creating new opportunities for automation, data collection, and personalized user experiences.</p>
          </div>
          <div className={Styles.automation}>
            <p className={Styles.automationtext}>automation</p><p className={Styles.automationtext1}>Automation technology streamlines website development by automating routine tasks, reducing errors, and increasing efficiency, freeing up valuable time for more strategic and creative work.</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className={Styles.testimonial}>
          <div className={Styles.text}>
            <p className={Styles.heading}>Testimonials</p>
            <p className={Styles.text1}>Discover What Our Satisfied Customers Have to Say About Our Exceptional Service and Results - Read Our Testimonials Today!" </p>
          </div>
          <div className={Styles.testimonialtext}>

          <Slider {...settings} className={Styles.slider_wrap}>
            <div className={Styles.slider}>
              <img className={Styles.img} src={quote1}></img>
              <p className={Styles.testimonialtext_p}>
                Metasoft has done an outstanding job in creating a website that authentically embodies the essence of 
                fragrances and nature. They have excelled in every aspect, from designing the logo to establishing a cohesive brand 
                identity and crafting the website's design and development. Their exceptional work perfectly captures the essence we 
                desired for our fragrance-focused website.</p>
              <img className={Styles.img1} src={quote2}></img>
              <div className={Styles.client}>
              Abraham Samuel
                <span>Managing Partner, Amber Aroma</span>
              </div>
            </div>
            <div className={Styles.slider}>
              <img className={Styles.img} src={quote1}></img>
              <p className={Styles.testimonialtext_p}>
              I am extremely pleased and grateful to the talented website team for creating an exceptional website that perfectly 
              showcases our services. Their ability to transform our vision into reality, with the unique concept of black-and-white 
              images that turn into vibrant colors when the cursor hovers over them, has exceeded our expectations. Their attention 
              to detail and technical expertise has resulted in a visually stunning and engaging website that will undoubtedly leave 
              a lasting impression on our customers. I am confident that this remarkable website will help us attract a wider audience
               and drive our business growth. Thank you for your dedication and exceptional work.
              </p>
              <img className={Styles.img1} src={quote2}></img>
              <div className={Styles.client}>
              Zaheer Marakkar
                <span>Managing Partner, Hostec</span>
              </div>
            </div>
            <div className={Styles.slider}>
              <img className={Styles.img} src={quote1}></img>
              <p className={Styles.testimonialtext_p}>
              I am thrilled to express my utmost satisfaction with the remarkable work accomplished by Metasoft in creating our website. Our primary goal was to develop a platform that effortlessly communicates with our audience, striking a balance between simplicity and elegance. Metasoft has truly exceeded our expectations by not only achieving this objective but also seamlessly integrating the functionality to sell our NFC Business Cards. The result is a captivating and user-friendly website that effectively showcases our products and engages our customers. The team's attention to detail, creative approach, and exceptional craftsmanship have made our vision a reality. We are incredibly grateful for their hard work and dedication, and we look forward to the continued success of our business with this beautiful website as our online storefront.
              </p>
              <img className={Styles.img1} src={quote2}></img>
              <div className={Styles.client}>
              Rajath Ravi
                <span>Founder, ZEEQR

</span>
              </div>
            </div>
          </Slider>
            
          </div>
        </div>
      </div>
      <button className={Styles.scrollTop} onClick={scrollToTop} >Scroll Top</button>
    </section>

  );
}

export default Home;
