import React from "react";
import Styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import H2 from '../../components/typography/h2';


function Technologys() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };


    return (
        <section>
            <div className="container">
                <p className="page_info_head">Technology</p>
                <div className={Styles.project_info}>
                    <H2>
                        <span>Vision </span> <span>to Reality: </span> <span> Unleashing the Power of </span>
                        <span>Our Website </span><span>Technology Process</span>
                    </H2>
                    <p>We are implementing the latest technology for the websites for a cutting-edge online experience</p>
                </div>
            </div>
            <div className="container-fluid">
                <div className={Styles.caseBg}>
                    <div className="tabStyle1 tabStyletech">
                        <Tabs>
                            <TabList>
                                <Tab>Artificial intelligence (AI)</Tab>
                                <Tab>Virtual reality (VR)</Tab>
                                <Tab>Internet of things (IoT)</Tab>
                                <Tab>Automation</Tab>
                            </TabList>
                            <TabPanel>
                                <div className="ai-bg-image">
                                    <div className={Styles.process}>
                                        <div className="container">
                                            <div className={Styles.process_discover}>
                                                <p className={Styles.process_descover_text}>Artificial intelligence (AI)
                                                </p>
                                                <div className={Styles.process_descover_01}>
                                                    <div className={Styles.process_descover_1_number}>
                                                        <p className={Styles.process_descover_1_number_1}>01.</p>
                                                        <p className={Styles.process_descover_text2}>Chatbots: Implementing chatbots on websites to provide instant and personalized customer support, answer frequently asked questions, and guide visitors through the website. </p>
                                                    </div>
                                                    <div className={Styles.process_descover_1_number}>
                                                        <p className={Styles.process_descover_1_number_1}>02.</p>

                                                        <p className={Styles.process_descover_text2}>Recommendation Systems: Using AI to analyze
                                                            user behavior and make personalized product or
                                                            content recommendations to visitors.
                                                        </p></div>
                                                    <div className={Styles.process_descover_1_number}>
                                                        <p className={Styles.process_descover_1_number_1}>03.</p>
                                                        <p className={Styles.process_descover_text2}>Image and Voice Recognition: Implementing
                                                            AI-powered image and voice recognition to
                                                            enhance the user experience on websites. For
                                                            example, websites can use image recognition to
                                                            suggest products based on the images users
                                                            upload.</p>   
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="vr-bg-image">
                                    <div className={Styles.process}>
                                        <div className="container">
                                            <div className={Styles.process_discover}>
                                                <p className={Styles.process_descover_text}>Virtual reality (VR)
                                                </p>
                                                <div className={Styles.process_descover_01}>
                                                    <div className={Styles.process_descover_1_number}>
                                                        <p className={Styles.process_descover_1_number_1}>01.</p>

                                                        <p className={Styles.process_descover_text2}>VR Product Demonstrations: Using VR to provide
                                                            product demonstrations, allowing users to
                                                            experience products in a virtual environment
                                                            before purchasing. </p>
                                                    </div>
                                                    <div className={Styles.process_descover_1_number}>
                                                        <p className={Styles.process_descover_1_number_1}>02.</p>

                                                        <p className={Styles.process_descover_text2}>VR Tours and Experiences: Using VR to provide
                                                            virtual tours and experiences, such as virtual
                                                            tours of museums, historical sites, or theme
                                                            parks.

                                                        </p></div>
                                                    <div className={Styles.process_descover_1_number}>
                                                        <p className={Styles.process_descover_1_number_1}>03.</p>

                                                        <p className={Styles.process_descover_text2}>VR Gaming: Integrating VR into websites to
                                                            provide online gaming experiences.
                                                        </p>   </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="iot-bg-image">
                                    <div className={Styles.process}>
                                        <div className="container">
                                            <div className={Styles.process_discover}>
                                                <p className={Styles.process_descover_text}>Internet of things (IoT)
                                                </p>
                                                <div className={Styles.process_descover_01}>
                                                    <div className={Styles.process_descover_1_number}>
                                                        <p className={Styles.process_descover_1_number_1}>01.</p>

                                                        <p className={Styles.process_descover_text2}>The Internet of Things (IoT) refers to the
                                                            interconnected network of physical devices, vehicles,
                                                            home appliances, and other items that are embedded
                                                            with sensors, software, and network connectivity,
                                                            allowing them to collect and exchange data. A
                                                            website can play a crucial role in the implementation
                                                            of IoT by providing a platform for users to interact
                                                            with IoT devices and view the data collected by them. </p>
                                                    </div>
                                                    <div className={Styles.process_descover_1_number}>
                                                        <p className={Styles.process_descover_1_number_1}>02.</p>

                                                        <p className={Styles.process_descover_text2}>For example, a website could be used to control
                                                            smart home devices, monitor the performance of
                                                            IoT-enabled devices such as wearable fitness trackers,
                                                            or provide real-time data and insights from industrial
                                                            IoT applications. Additionally, a website can also be
                                                            used to manage and analyze the vast amounts of
                                                            data generated by IoT devices, allowing
                                                            organizations to make data-driven decisions and
                                                            improve their operations.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="auto-bg-image">
                                    <div className={Styles.process}>
                                        <div className="container">
                                            <div className={Styles.process_discover}>
                                                <p className={Styles.process_descover_text}>Automation
                                                </p>

                                                <div className={Styles.process_descover_01}>

                                                    <div className={Styles.process_descover_1_number}>
                                                        <p className={Styles.process_descover_1_number_1}>01.</p>

                                                        <p className={Styles.process_descover_text2}>Streamlined processes: Automation can be used
                                                            to automate repetitive or time-consuming tasks
                                                            within a website, such as updating content,
                                                            managing customer data, or processing payments.
                                                            This can improve efficiency and reduce the time
                                                            required to complete these tasks.
                                                        </p>
                                                    </div>
                                                    <div className={Styles.process_descover_1_number}>
                                                        <p className={Styles.process_descover_1_number_1}>02.</p>

                                                        <p className={Styles.process_descover_text2}>Improved customer experience: Automated
                                                            processes can be used to enhance the customer
                                                            experience on a website, such as through
                                                            chatbots or personalized product
                                                            recommendations.

                                                        </p></div>
                                                    <div className={Styles.process_descover_1_number}>
                                                        <p className={Styles.process_descover_1_number_1}>03.</p>

                                                        <p className={Styles.process_descover_text2}>Increased accuracy: Automated processes are
                                                            less prone to error than manual processes,
                                                            resulting in more accurate data and fewer errors.</p>   </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
            <button className={Styles.scrollTop} onClick={scrollToTop} >Scroll Top</button>
        </section>
    );
}

export default Technologys;
